import React, { useState } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import imgs from '../images/BG.png'
import Fade from 'react-reveal/Fade'
import { Link } from "gatsby"



const Headline = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div className="container">
      <h1 className="main-headline">
        <motion.span animate={{ opacity: hovered ? 0.2 : 1 }}>
        My life passion is to build companies that poeple<span className="semi-bold"> love using and enable their entrepreneurial spirit.</span> An independent
          <span className="semi-bold"> visual designer</span> specializes in
          {" "}
         
        </motion.span>
        <motion.span
          onHoverStart={() => setHovered(!hovered)}
          onHoverEnd={() => setHovered(!hovered)}
          className="semi-bold underline"
        >
        <Link to="/showreel">
         minimal UI and functional UX design 
         </Link>
         
        </motion.span>{" "}
        <motion.span animate={{ opacity: hovered ? 0.2 : 1 }}>
        for startups..{" "}
        </motion.span>
      </h1>
    </div>
  )
}

const Accomplishments = () => (
  <div className="container">
    <div className="accomplishments-inner">
      <ul>
       <span><Link to="/accomplishments">Design</Link></span>
        <li>UI. UX. Design</li>
        <li>3D Modeling</li>
        <li>Graphic Design</li>
      </ul>
      <ul>
      <span> <Link to="/accomplishments">Full Stack Development</Link></span>
        <li>Node. Python. C++</li>
        <li>ReactJS</li>
        <li>React Native</li>
      </ul>
      <ul>
      <span><Link to="/accomplishments">Cloud Infra-Architecture</Link></span>
        <li>Amazon Web Services</li>
        <li>Google Cloud Platform</li>
        <li>Saas (Salesforce..)</li>
      </ul>
    </div>
  </div>
)


const ScrollForWork = () => {
  // Fade view work out
  const { scrollYProgress } = useViewportScroll()
  const opacity = useTransform(scrollYProgress, [0, 0.7], [1, 0])
  return (
    <motion.div style={{ opacity }} className="scroll-for-work">
     <a href="https://www.index.paaolms.com">
      <span>INDEX</span>
      <span className="line"></span>
      </a>
    </motion.div>
  )
}


const Sphere = () => {
  // Fade view work out
  const { scrollYProgress } = useViewportScroll()
  const opacity = useTransform(scrollYProgress, [0, 0.7], [1, 0])
  return (
    <motion.div style={{ opacity }} className="SphereMotion">
      <img src={imgs} alt="circle design strategy" />
    </motion.div>
  )
}

const Banner = () => (
  <>
  
    <div className="banner">
      <div className="inner-banner">
      <Fade top delay={30}>
        <Headline />
        </Fade>
        <Fade bottom delay={90}>
        <Accomplishments />
        </Fade>
      </div>
      <ScrollForWork />
    </div>
  </>
)

export default Banner
